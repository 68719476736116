<template>
  <dehydration-template
    :dryness-occurrence="drynessOccurrence"
    :field-errors="fieldErrors"
    @field-change="onFieldChange"
  />
</template>

<script>
import DehydrationTemplate from '@/modules/questionnaire/components/steps/questionnaire/skin-dryness/dehydration/DehydrationTemplate';

import { makeStep } from '@/modules/questionnaire/mixins';
import { fieldBuilder } from '@/modules/questionnaire/mixins/makeStep';

const { requiredArrayField } = fieldBuilder;

const FIELDS = [requiredArrayField('drynessOccurrence')];

export default {
  name: 'Dehydration',
  components: {
    DehydrationTemplate
  },
  mixins: [makeStep(FIELDS)]
};
</script>
